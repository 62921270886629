@use 'colors';
@import "../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: colors.get(neutrals, grey-900);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: colors.get(primary, teal-vivid-600);
}

.app-body {
  background: colors.get(neutrals, grey-100);
  height: 100vh;
}

.sequencer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}

.sequencer-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}

.step-number {
  @extend .step;
  background-color: colors.get(neutrals, grey-800);
  color: colors.get(primary, teal-vivid-600);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  margin-right: 3px;
  border-style: none;
}

.transport-btn {
  width: 50px;
  height: 50px;
  padding: 6px 0px;
  border-radius: 20px;
  font-size: 8px;
  text-align: center;
  margin-right: 3px;
  border-style: none;
  font-size: 15px;
}

.transport-btn:hover {
  background-color: colors.get(primary, teal-vivid-700);
}

.transport-btn-red {
  @extend .transport-btn;
}

.transport-btn-red:hover {
  color: colors.get(neutrals, grey-050);
  background-color: colors.get(supporting, red-vivid-800);
}

.transport-btn-enabled {
  width: 50px;
  height: 50px;
  padding: 6px 0px;
  border-radius: 20px;
  font-size: 8px;
  text-align: center;
  margin-right: 3px;
  border-style: none;
  background-color: colors.get(primary, teal-vivid-700);
  color: colors.get(neutrals, grey-050);
  font-size: 15px;
}

.transport-btn-enabled:hover {
  background-color: colors.get(primary, teal-vivid-400);
}

.note-active-enabled {
    background-color: colors.get(supporting, yellow-vivid-900);
}

.note-active-enabled:hover {
  background-color: colors.get(supporting, yellow-vivid-800);
}

.note-active {
    background-color: colors.get(supporting, yellow-vivid-600);
}

.note-active:hover {
  background-color: colors.get(supporting, yellow-vivid-500);
}

.note-enabled {
    background-color: colors.get(supporting, yellow-vivid-500);
}

.note-enabled:hover {
  background-color: colors.get(supporting, yellow-vivid-400);
}

.note-idle {
    background-color: colors.get(neutrals, grey-200);
}

.note-idle:hover {
  background-color: colors.get(neutrals, grey-100);
}

.sequencer-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 7px;
  margin: 7px;
  border-radius: 15px;
  max-height: 44px;
}

.instrument-line {
  background: colors.get(neutrals, grey-050);
}

.step {
  width: 30px;
}

.note-button {
  border: none;
  background: none;
  padding-left: 0px;
  padding-right: 0px;
}

.instrument-notes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.instrument-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.instrument-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: right;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: colors.get(primary, teal-vivid-900);
}

.instrument-overlay {
  cursor: help;
}

.transport {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 7px;
}

.param-input {
  display: flex;
  flex-direction: row;
  width: 8em;
  margin-right: 3px;
  height: 50px;
}

.instrument-action {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  margin-right: 3px;
  border-style: none;
  color: colors.get(neutrals, grey-900);
}

.delete-instrument {
  @extend .instrument-action;
}

.mute-instrument, .solo-instrument {
  @extend .instrument-action;
  font-weight: 700;
}

.solo-instrument-enabled {
  @extend .instrument-action;
  color: colors.get(neutrals, grey-050);
  background-color: colors.get(primary, teal-vivid-800);
}

.mute-instrument-enabled {
  @extend .instrument-action;
  color: colors.get(neutrals, grey-050);
  background-color: colors.get(supporting, yellow-vivid-400);
}

.solo-instrument-enabled:hover,
.mute-instrument-enabled:hover,
.solo-instrument:hover,
.mute-instrument:hover
{
  color: colors.get(neutrals, grey-050);
  background-color: colors.get(neutrals, grey-700);
}

.delete-instrument:hover {
  color: colors.get(neutrals, grey-050);
  background-color: colors.get(supporting, red-vivid-800);
}

.found-sound-list-item {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.found-sound {
  width: 100%;
  height: 32px;
  border: 0px;
  margin: 0px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: colors.get(primary, teal-vivid-050);
}

.found-sound:hover {
  background-color: colors.get(primary, teal-vivid-200);
}

.add-instrument {
  color: colors.get(primary, teal-vivid-900);
}

.found-sound:hover .add-instrument {
  color: colors.get(primary, teal-vivid-050);
}

.found-sound-list {
  width: 100%;
}

.add-instrument-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30rem;
}

.add-instrument-name {
  width: 80%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
